import React, { useState } from 'react'
import Styles from "../styles/components/HeaderMenu.module.scss"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx"

export const HeaderMenu = ( {
    link
}: any ) => {
    const [ drawerOpen, setDrawerOpen ] = useState( false )

    return (
        <li className={ clsx( Styles.menu_item, drawerOpen && Styles.open ) } key={ link.name }>
            <Link to={ link.path }>
                { link.name }
            </Link>
            { link.menuList.length !== 0 &&
                <span className={ Styles.icon_wrapper } onClick={ () => setDrawerOpen( !drawerOpen ) } >
                    <FontAwesomeIcon
                        icon={ drawerOpen ? faAngleUp : faAngleDown }
                        className={ Styles.icon } />
                </span >
            }
            <div className={ Styles.hidden_tree }>
                <div className={ Styles.hidden_tree_content }>
                    <ul>
                        { link.menuList.map( (item: any) => <li key={ item.path }>
                            <Link to={ item.path }>
                                <span>
                                    { item.name }
                                </span>
                            </Link>
                        </li>
                        ) }
                    </ul>
                </div>
            </div>
        </li>
    );
}
