import React from "react"
import { FaChevronDown } from "react-icons/fa";
import { CSSProperties, FC } from "react";

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const DownArrowIcon: FC<Props> = ( { className, style, onClick } ) =>
    <FaChevronDown className={ className } style={ style } onClick={ onClick } />
