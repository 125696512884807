import React, { FC, useState, useRef } from 'react'
import Styles from "../styles/components/HeaderTableOfContent.module.scss"
import clsx from "clsx"
import { useClickAway } from "../hooks/useClickAway"
import { DownArrowIcon } from '../icons/DownArrowIcon'
import { HeaderTableOfContent } from './HeaderTableOfContent'
import { useTableOfContent } from '../hooks/useTableOfContent'


export const TableOfContnetsLabel: FC =() => {

    const [ contentVisible, setContentVisible ] = useState( false );
    const { tableOfContent } = useTableOfContent()

    const labelRef = useRef<HTMLDivElement>( null )
    useClickAway( {
        clickExceptRef: labelRef,
        clickAwayFunction: () => setContentVisible( false )
    } )


    return (
        !!tableOfContent.length ?
            <div className={ Styles.container }>
                <div ref={ labelRef } className={ clsx( Styles.label_wrapper, contentVisible && Styles.open_label_wrapper ) } onClick={ () => setContentVisible( true ) }>
                    <label className={ Styles.label }>このページの目次</label>
                    <DownArrowIcon className={ clsx( Styles.icon, contentVisible && Styles.up_arrow_icon ) } />
                </div>
                <HeaderTableOfContent contentVisible={ contentVisible } />
            </div>:
            null
    )
}
