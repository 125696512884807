import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contentType } from '../components/TableOfContents'
import { RootState } from "../stores"
import { TableOfContentPropsType, setContent} from '../stores/tableOfContent'

const threshold = 300

const getHeadingPosition = ( { tagName, text }: { tagName: string, text: string } ) => {
    const elements = document.getElementsByTagName( tagName )
    const element = Array.from( elements ).find( e => e.innerHTML === text )
    const top = element?.getBoundingClientRect().top
    return top ? top + window.scrollY : undefined
}

const getCurrentHeadingIndex = ( content: contentType[] ) => {
    const viewPortScroll = window.scrollY + threshold
    const heading2Positions = generateHeading2Positions( content )
    const currentHeadingIndex = getLessAndNearestNumberIndex( { numArray: heading2Positions, targetNum: viewPortScroll } )
    return currentHeadingIndex
}

const generateHeading2Positions = ( tableOfContent: contentType[] ) =>
    tableOfContent.map( c => getHeadingPosition( { tagName: c.tagName, text: c.text } ) ).filter( e => !!e ) as number[]

const getLessAndNearestNumberIndex = ( { numArray, targetNum }: { numArray: number[], targetNum: number } ) => {
    let greaterAndNearestNumIndex = 0
    for ( let index = 0; index < numArray.length; index++ ) {
        if ( numArray[ index ] < targetNum ) {
            greaterAndNearestNumIndex = index
        }
        else {
            break
        }
    }
    return greaterAndNearestNumIndex
}

const getHeading2DOMs = ( tableOfContent: contentType[] ) => {
    return tableOfContent.map( c => getElement( { tagName: c.tagName, text: c.text } ) ).filter( c => c!! ) as Element[]
}

const getElement = ( { tagName, text }: { tagName: string, text: string } ) => {
    const elements = document.getElementsByTagName( tagName )
    const element = Array.from( elements ).find( e => e.innerHTML === text )
    return element
}

export const useTableOfContent = ( ) => {

    const tableOfContent = useSelector( ( state: RootState ) => state.tableOfContent.content )
    const [ currentHeading2Index, setCurrentHeading2Index ] = useState( 0 );
    const [ lastInterSectDateTime, setLastInterSectDateTime ] = useState( new Date() );
    const [ registerObserver, setRegisterObserver ] = useState( false );

    useEffect( () => {
        if ( !tableOfContent.length || registerObserver ) return
        setTimeout( registerScrollObserver, 1000 )
        setRegisterObserver( true )
    }, [ tableOfContent ] )

    function callback ( entries: IntersectionObserverEntry[], object: IntersectionObserver ) {
        entries.forEach( function ( entry, i ) {
            if ( !entry.isIntersecting ) return;
            setLastInterSectDateTime( new Date() )
        } );
    };

    const registerScrollObserver = () => {
        const options = {
            root: null,
            rootMargin: `-${ threshold }px 0px`,
            threshold: [ 0, 0.5, 1.0 ]
        };
        const observer = new IntersectionObserver( callback, options );
        const heading2List = getHeading2DOMs( tableOfContent )

        heading2List.forEach( heading2 =>
            observer.observe( heading2 )
        )
    }

    useEffect( () => {
        const currentHeadingIndex = getCurrentHeadingIndex( tableOfContent )
        setCurrentHeading2Index( currentHeadingIndex )
    }, [ lastInterSectDateTime ] )

    const dispatch = useDispatch();

    return {
        tableOfContent,
        setContent: ( props: TableOfContentPropsType ) => dispatch( setContent( props ) ),
        resetContent: ( ) => dispatch( setContent( {content:[]} ) ),
        currentHeading2Index
    }
}
