import { RefObject, useEffect } from "react"

type props = {
    clickExceptRef: RefObject<HTMLElement>,
    clickAwayFunction: () => void,
    executeWhenClickInside?: boolean
}

export const useClickAway = ( {
    clickExceptRef,
    clickAwayFunction,
    executeWhenClickInside = false
}: props ) => {
    const documentClickHandler = ( e: any ) => {
        // if( !executeWhenClickInside && clickExceptRef.current && clickExceptRef.current.contains( e.target ) ) return
        if ( clickExceptRef.current && clickExceptRef.current.contains( e.target ) ) return
        clickAwayFunction()
    }

    useEffect( () => {
        document.addEventListener( 'click', e => {
            // setTimeout( () => documentClickHandler( e ), 500 )
            documentClickHandler( e )
            document.removeEventListener( 'click', documentClickHandler )
        } )
        return () => document.removeEventListener( 'click', documentClickHandler )
    }, [] )
}
