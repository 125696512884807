import React, { FC } from 'react'
import Styles from "../styles/components/HeaderTableOfContent.module.scss"
import clsx from "clsx"
import { useTableOfContent } from '../hooks/useTableOfContent'


type Props = {
    contentVisible: boolean
}

export const HeaderTableOfContent: FC<Props> = ( { contentVisible } ) => {

    const { tableOfContent, currentHeading2Index } = useTableOfContent()

    const scrollToHeading = ( headingObject: any ) => {
        const headingList = Array.from( document.getElementsByTagName( headingObject.tagName ) )
        const heading = headingList.find( heading => heading.innerHTML === headingObject.text )
        const featureY = heading.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const buffer = 64
        const top = featureY + offsetTop - buffer
        window.scrollTo( {
            top,
            behavior: "smooth"
        } );
    }

    if ( !tableOfContent.length ) return null

    return (
        <div className={ clsx( Styles.content, contentVisible && Styles.visible_content ) }>
            <ol className={ Styles.heading2_list }>
                { tableOfContent.map( ( heading2, index ) =>
                    <li
                        className={ clsx( Styles.heading2_list_item, index === currentHeading2Index && Styles.current_heading2_item ) }
                        onClick={ () => scrollToHeading( heading2 ) } key={ heading2.text }>
                        <div
                            className={ Styles.heading2 }>
                            { heading2.text }
                        </div>
                        { !!heading2.children.length &&
                            <ol className={ Styles.heading3_list }>
                                { heading2.children.map( heading3 =>
                                    <li
                                        className={ Styles.heading3_list_item }
                                        onClick={ ( e ) => {
                                            e.stopPropagation();
                                            scrollToHeading( heading3 )
                                        } }
                                        key={ heading3.text }>
                                        { heading3.text }
                                    </li>
                                ) }
                            </ol> }
                    </li> ) }
            </ol>
        </div>
    )
}
