import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Styles from "../styles/components/Layout.module.scss"
import { Header } from '../components/Header'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

export const Layout = ( {
    children,
    OGPImage
}: any ) => {
    const { title, description } = useSiteMetadata()
    return (
        <div>
            <Helmet>
                <html lang="ja" />
                <title>{ title }</title>
                <meta name="description" content={ description } />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={ `${ withPrefix( '/' ) }media/apple-touch-icon.png` }
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={ `${ withPrefix( '/' ) }media/favicon-32x32.png` }
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={ `${ withPrefix( '/' ) }media/favicon-16x16.png` }
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={ `${ withPrefix( '/' ) }media/safari-pinned-tab.svg` }
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={ title } />
                <meta property="og:url" content="/" />
                { OGPImage && <meta
                    property="og:image"
                    // content={ `${ withPrefix( '/' ) }media/og-image.jpg` }
                    content={ OGPImage }
                /> }
            </Helmet>
            <Header />
            <div className={ Styles.container}>
                { children }
            </div>
            <Footer />
        </div>
    )
}

