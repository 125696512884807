import React from 'react'
import { Link } from 'gatsby'
import Styles from "../styles/components/Footer.module.scss"


import logo from '../media/logo.svg'
import facebook from '../media/social/facebook.svg'
import instagram from '../media/social/instagram.svg'
import twitter from '../media/social/twitter.svg'
import vimeo from '../media/social/vimeo.svg'

const FooterLinkList = [
    { name: 'バク宙', path: '/backflip/' },
    { name: '前宙', path: '/frontflip/' },
    { name: '助走技', path: '/flip-assisting-skill/' },
    { name: 'その他の技', path: '/other-skills/' },
    { name: 'コツまとめ', path: '/tips/' },
    { name: 'その他コンテンツ', path: '/misc/' }
]

const Footer = () => {
    return (
        <footer className={ Styles.footer }>
            <div className={ Styles.footer_link_list_container }>
                <ul>
                    { FooterLinkList.map( link =>
                        <li key={ link.name }>
                            <Link to={ link.path }>
                                { link.name }
                            </Link>
                        </li> ) }
                </ul>
            </div>
            <p className={ Styles.copy_right }>
                <small>Copyright ©︎ちゅうがえりろん All rights reserved.</small>
            </p>
        </footer>
    )
}

export default Footer
