import React, { useState, useEffect } from 'react'
import Styles from "../styles/components/Header.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { HeaderMenu } from '../components/HeaderMenu';
import clsx from "clsx"
import { useTableOfContent } from '../hooks/useTableOfContent';
import { HeaderTableOfContent } from './HeaderTableOfContent';
import { Link } from 'gatsby';
import { TableOfContnetsLabel } from './TableOfContnetsLabel';

const menuLinks = [
    {
        name: 'トップ',
        path: '/',
        menuList: []
    },
    {
        name: 'バク宙',
        path: '/backflip/',
        menuList: [
            { name: "スタン宙", path: '/backflip/standing-backflip/' },
            { name: "スタン猫宙", path: '/backflip/standing-arabian-backflip/' },
            { name: "スタン宙1回ひねり", path: '/backflip/standing-backflip-full-twist/' },
            { name: "ロンダートからバク宙", path: '/backflip/backflip-from-round-off/' }
        ]
    },
    {
        name: '前宙',
        path: '/frontflip',
        menuList: [
            { name: "スタン前宙", path: '/frontflip/standing-frontflip' },
            { name: "走って前宙", path: '/frontflip/running-frontflip' },
            { name: "屈身前宙", path: '/frontflip/pike-frontflip' },
            { name: "伸身前宙", path: '/frontflip/layout-frontflip' },
            { name: "とび込み前転", path: '/frontflip/dive-roll' },
            { name: "とび込み前転1回ひねり", path: '/frontflip/dive-roll-full-twist' },
        ]
    },
    {
        name: '助走技',
        path: '/flip-assisting-skill',
        menuList: [
            { name: "ロンダート(宙返り用)", path: '/flip-assisting-skill/round-off-for-flip' },
            { name: "ロンダート(バク転用)", path: '/flip-assisting-skill/round-off-for-back-handspring' },
            { name: "バク転", path: '/flip-assisting-skill/back-handspring' },
            { name: "ロンダートからバク転", path: '/flip-assisting-skill/round-off-to-back-handspring' },
            { name: "宙返りの刺し方", path: '/flip-assisting-skill/punching' },
        ]
    },
    {
        name: 'その他の技',
        path: '/other-skills',
        menuList: [
            { name: "倒立前転", path: '/other-skills/handstand-roll' },
        ]
    },
    {
        name: 'コツまとめ',
        path: '/tips/',
        menuList: [
            { name: "高さを得るコツ", path: '/tips/get-more-height/' },
            { name: "回転を得るコツ", path: '/tips/get-more-rotation/' },
            { name: "よくある間違った理論", path: '/tips/incorrect-tips/' },
            { name: "コラム", path: '/columns/' }
        ]
    },
    {
        name: 'その他コンテンツ',
        path: '/misc/',
        menuList: [
            { name: "このサイトについて", path: '/about/' },
            { name: "よくわかる物理", path: '/physics/' },
            { name: "アクロバット用語", path: '/terms-in-acrobat/' },
            { name: "最新の投稿", path: '/latest-post/' },
            { name: "お問い合わせ", path: '/contact/' },
        ]
    },
]

export const Header = () => {

    const [ drawerOpen, setDrawerOpen ] = useState( false )
    const [ headerScrolled, setHeaderScrolled ] = useState( false )
    const { tableOfContent } = useTableOfContent()

    useEffect( () => {
        toggleHeaderScroll()
        document.addEventListener( "scroll", toggleHeaderScroll, { passive: false } )
        return () => document.removeEventListener( "scroll", toggleHeaderScroll )
    }, [] )

    const toggleHeaderScroll = () => {
        const scroll = document.documentElement.scrollTop
        scroll < 60 ?
            setHeaderScrolled( false ) :
            setHeaderScrolled( true )
    }

    return (
        <div className={ Styles.container }>
            <header className={ clsx( headerScrolled && Styles.scrolled_header ) }>
                <Link to="/">
                    <span className={ Styles.site_name }>ちゅうがえりろん</span>
                </Link>
                <div>
                    <TableOfContnetsLabel />
                </div>
                <label className={ Styles.drawer_label } htmlFor="drawer_input" onClick={ () => setDrawerOpen( true ) }>
                    <div></div>
                    <div></div>
                    <div></div>
                </label>
                <nav className={ Styles.menu } style={ drawerOpen ? { transform: "translate3d(0,0,0)" } : {} }>
                    <FontAwesomeIcon icon={ faTimes } className={ Styles.drwer_close_icon } onClick={ () => setDrawerOpen( false ) } />
                    <ul className={ Styles.menu_list }>
                        { menuLinks.map( link => (
                            <HeaderMenu link={ link } key={ link.name } />
                        ) ) }
                    </ul>
                </nav>
                <div
                    className={ Styles.drawer_background }
                    style={ drawerOpen ? {} : { display: "none" } }
                    onClick={ () => setDrawerOpen( false ) }>
                </div>
            </header>
        </div>
    )
}
